// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-ca-js": () => import("./../../../src/pages/404.ca.js" /* webpackChunkName: "component---src-pages-404-ca-js" */),
  "component---src-pages-404-en-js": () => import("./../../../src/pages/404.en.js" /* webpackChunkName: "component---src-pages-404-en-js" */),
  "component---src-pages-events-ca-js": () => import("./../../../src/pages/events.ca.js" /* webpackChunkName: "component---src-pages-events-ca-js" */),
  "component---src-pages-events-en-js": () => import("./../../../src/pages/events.en.js" /* webpackChunkName: "component---src-pages-events-en-js" */),
  "component---src-pages-index-ca-js": () => import("./../../../src/pages/index.ca.js" /* webpackChunkName: "component---src-pages-index-ca-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-individual-sessions-ca-js": () => import("./../../../src/pages/individual-sessions.ca.js" /* webpackChunkName: "component---src-pages-individual-sessions-ca-js" */),
  "component---src-pages-individual-sessions-en-js": () => import("./../../../src/pages/individual-sessions.en.js" /* webpackChunkName: "component---src-pages-individual-sessions-en-js" */),
  "component---src-pages-music-stimulation-ca-js": () => import("./../../../src/pages/music-stimulation.ca.js" /* webpackChunkName: "component---src-pages-music-stimulation-ca-js" */),
  "component---src-pages-music-stimulation-en-js": () => import("./../../../src/pages/music-stimulation.en.js" /* webpackChunkName: "component---src-pages-music-stimulation-en-js" */),
  "component---src-pages-nursery-schools-ca-js": () => import("./../../../src/pages/nursery-schools.ca.js" /* webpackChunkName: "component---src-pages-nursery-schools-ca-js" */),
  "component---src-pages-nursery-schools-en-js": () => import("./../../../src/pages/nursery-schools.en.js" /* webpackChunkName: "component---src-pages-nursery-schools-en-js" */),
  "component---src-pages-preventive-therapy-ca-js": () => import("./../../../src/pages/preventive-therapy.ca.js" /* webpackChunkName: "component---src-pages-preventive-therapy-ca-js" */),
  "component---src-pages-preventive-therapy-en-js": () => import("./../../../src/pages/preventive-therapy.en.js" /* webpackChunkName: "component---src-pages-preventive-therapy-en-js" */)
}

